import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseResponse } from '../models/responses/base.response';
import { BaseCrudService } from './base-crud.service';
import { RequestService } from './request.service';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { Crop } from '../models/crop';
import { HarvestEstimation } from '../models/estimation';

@Injectable({
  providedIn: 'root',
})
export class KumrongService extends BaseCrudService<null> {
  protected path: string;
  protected dashboardPath: string = '';
  protected reportPath: string = '';
  protected requestService: RequestService;

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.kumrong_url;
    this.dashboardPath = environment.kumrong_url + '/dashboard';
    this.reportPath = environment.kumrong_url + '/reports';

    this.requestService = injector.get(RequestService);
  }

  getCycleByStage(farmerID, farmlandID, cycleID): Observable<BaseResponse> {
    let url =
      '/farmers/' +
      farmerID +
      '/farmlands/' +
      farmlandID +
      '/cycles/' +
      cycleID +
      '/by_stages';
    return this.requestService.get(this.path + url);
  }

  getTemplate(cropId): Observable<BaseResponse> {
    return this.requestService.get(
      this.path + '/templates/' + cropId + '/mobile'
    );
  }

  getCycleActivities(urlPath): Observable<BaseResponse> {
    return this.requestService.get(this.path + urlPath);
  }

  // get activity detail
  getCycleActivity(urlPath): Observable<BaseResponse> {
    return this.requestService.get(this.path + urlPath);
  }

  // templates
  getTemplates(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/templates', { data });
  }
  postStagesActivate(_id: string,data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(`${this.path}/stages/${_id}/activate`, { data });
  }
  getTpStages(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/stages', { data });
  }

  postTemplate(data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(this.path + '/templates', { data });
  }

  putTemplate(_id, data: {}): Observable<BaseResponse> {
    return this.requestService.putJSON(this.path + '/templates/' + _id, {
      data,
    });
  }

  RemoveTemplate(_id): Observable<BaseResponse> {
    return this.requestService.deleteJSON(this.path + '/templates/' + _id);
  }

  getCcTemplate(_id): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/templates/' + _id);
  }

  // data for dashboard
  getTotalFarmland(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/dashboard/total-farmlands', {
      data,
    });
  }

  // data for dashboard
  getTotalCropCycle(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/dashboard/total-crop-cycles', {
      data,
    });
  }

  deleteStage(_id): Observable<BaseResponse> {
    return this.requestService.deleteJSON(this.path + '/stages/' + _id);
  }

  postStage(data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(this.path + '/stages', { data });
  }

  putStage(_id, data: {}): Observable<BaseResponse> {
    return this.requestService.putJSON(this.path + '/stages/' + _id, { data });
  }

  reopenCropCycle(farmerID, farmlandID, cycleID, data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(
      `${this.path}/farmers/${farmerID}/farmlands/${farmlandID}/cycles/${cycleID}/reopen`,
      { data }
    );
  }
  postCropsActivate(_id: string,data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(`${this.path}/crops/${_id}/activate`, { data });
  }
  getCrops(data: {}): Observable<BaseResponse> {
    return this.requestService.getJSON(this.path + '/crops', {data})
  }

  getCropType() {
    return this.requestService.getJSON(this.path + '/crop_types')
  }

  postCrop(data: {}): Observable<BaseResponse> {
    return this.requestService.postJSON(this.path + '/crops', {data})
  }

  putCrop(crop_id: string, data: {}): Observable<BaseResponse> {
    return this.requestService.putJSON(this.path + '/crops/' + crop_id, { data });
  }

  // get crop cycles for estimation report
  getCropCycles(): Observable<Crop[]> {
    return this.requestService.getJSON(`${this.path}/crops/cycles`);
  }

  // get today harvest estimation in dashboard
  getHarvestEstimationDashboard(): Observable<BaseResponse> {
    return this.requestService.get(
      `${this.path}/dashboard/harvest_estimate`
    )
  }

  getMonthlyHarvestYield(data: {}): Observable<BaseResponse> {
    return this.requestService.get(
      `${this.dashboardPath}/harvest_estimate_by_year`, { data }
      );
    }

    getProvincialHarvestYield(data: {}): Observable<BaseResponse> {
      return this.requestService.get(
      `${this.dashboardPath}/harvest_estimate_by_province`, { data }
    );
  }

  getAnnualHarvestYieldReport(data: {}): Observable<BaseResponse> {
    return this.requestService.get(
      `${this.reportPath}/harvest_estimate_by_year`, { data }
    );
  }

  getProvincialHarvestYieldReport(data: {}): Observable<BaseResponse> {
    return this.requestService.get(
      `${this.reportPath}/harvest_estimate_by_province`, { data }
    );
  }


}
