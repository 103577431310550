import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { LocalStorageService } from '../services/local-storage.service';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';
import { RefreshTokenResponse } from '../models/responses/refresh-token.response';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private http: HttpClient
  ) { }
  isRefreshingToken: boolean;
  tokenBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const refresh_token_url = environment.api_url + '/auth/refresh-token';
    const refresh_token = this.localStorageService.get(LocalStorageEnum.refresh_token);
    let token = this.localStorageService.get(LocalStorageEnum.token);

    if (token) {
      req = this.addToken(req, token);
    }
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // You may notice that if refresh token called twice, it will cause an error as a result of logging out
          if (req.url === refresh_token_url) {
            this.logout();
          } else if (refresh_token) {
            if (!this.isRefreshingToken) {
              this.isRefreshingToken = true;

              // Reset here so that the following requests wait until the token was refreshed
              // comes back from the refreshToken calls. No matter how many requests are waiting

              this.tokenBehaviorSubject.next('');
              // get a new token via userService.refreshToken
              return this.http.post<RefreshTokenResponse>(refresh_token_url, {
                refreshToken: refresh_token
              }).
                pipe(
                  switchMap((res) => {
                    this.localStorageService.set(LocalStorageEnum.token, res.data.token);
                    this.localStorageService.set(LocalStorageEnum.refresh_token, res.data.refresh_token);
                    this.tokenBehaviorSubject.next(res.data.token);
                    this.authService.authChange$.next(true);
                    return next.handle(this.addToken(req, res.data.token));
                  }),
                  catchError(err => {
                    // If we don't get a new token, we are in trouble so logout.
                    this.logout();
                    return throwError(() => err);
                  }),
                  finalize(() => {
                    this.isRefreshingToken = false;
                  })
                );
            } else {
              return this.tokenBehaviorSubject
                .pipe(
                  filter(token => token != ''),
                  take(1),
                  switchMap(token => {
                    return next.handle(this.addToken(req, token));
                  })
                );
            }
          } else {
            this.logout();
          }
        }
        return throwError(() => err);
      })
    )
  }


  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        "Authorization": 'Bearer ' + token
      }
    });
  }

  private logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }
}
