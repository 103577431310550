import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleEnum } from './models/enums/role.enum';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./routes/privacy/privacy-routing.module').then(
        m => m.PrivacyRoutingModule
      )
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ENTREPRENEUR_MANAGER
          ],
        }
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/user-list/user-list.module').then(
            (m) => m.UserListModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ENTREPRENEUR_MANAGER
          ]
        }
      },
      {
        path: 'market',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/market/market.module').then(
            (m) => m.MarketModule
          ),
        data: {
          roles: [
            RoleEnum.ENTREPRENEUR,
            RoleEnum.ORGANIZATION_STAFF,
          ]
        }
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ENTREPRENEUR_MANAGER,
            RoleEnum.ENTREPRENEUR,
            RoleEnum.ORGANIZATION_STAFF,
          ]
        }
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/report/report.module').then((m) => m.ReportModule),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ENTREPRENEUR_MANAGER,
            RoleEnum.ENTREPRENEUR,
            RoleEnum.ORGANIZATION_STAFF,
          ]
        }
      },
      {
        path: 'annual-yield',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/annual-yield/annual-yield.module').then(
            (m) => m.AnnualYieldModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.ORGANIZATION_STAFF
          ]
        }
      },
      {
        path: 'provincial-yield',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/provincial-yield/provincial-yield.module').then(
            (m) => m.ProvincialYieldModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.ORGANIZATION_STAFF
          ]
        }
      },
      {
        path: 'nutrient',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/nutrient/nutrient.module').then(
            (m) => m.NutrientModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER
          ]
        }
      },
      {
        path: 'soil-entry',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/soil-entry/soil-entry.module').then(
            (m) => m.SoilEntryModule
          ),
        data: {
          roles: [
            RoleEnum.ENTREPRENEUR_MANAGER
          ]
        }
      },
      {
        path: 'map',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/map/map.module').then(
            (m) => m.MapModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.ORGANIZATION_MANAGER,
            RoleEnum.PROVINCIAL_MANAGER,
            RoleEnum.ENTREPRENEUR_MANAGER
          ]
        }
      },
      {
        path: 'crop-cycle-templates',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/cc-templates/cc-templates.module').then(
            (m) => m.CcTemplatesModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER
          ]
        }
      },
      {
        path: 'crop',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/crop/crop.module').then(
            (m) => m.CropModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER
          ]
        }
      },
      {
        path: 'organization',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/organization/organization.module').then(
            m => m.OrganizationModule
          ),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER
          ]
        }
      },
      {
        path: 'disaster-warning',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/disaster-warning/disaster-warning.module').then((m) => m.DisasterWarningModule),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER,
            RoleEnum.DISASTER_MANAGER
          ]
        }
      },
      {
        path: 'manage-weather-parameter',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/manage-weather-parameter/manage-weather-parameter.module').then((m) => m.ManageWeatherParameterModule),
        data: {
          roles: [
            RoleEnum.NATIONAL_MANAGER
          ]
        }
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }
